import React from 'react';
import { Route, Link, Switch } from 'react-router-dom'

import Header from './Header'
import List from './List2'
import Footer from './Footer'

export class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = { name: null, comment: null, list: [], isLoading: true }
    }

    componentWillMount() {
        this.fetchJSON()
    }

    async fetchJSON() {
        // const { id } = this.props.match.params
        const id = this.props.location.hash.split('/')[1]

        const response = await fetch(`https://s3.amazonaws.com/u.100things.life/${id}.json`, { mode: 'cors', content_type: 'application/json' })
        const json = await response.json()

        this.setState({ name: json.name, comment: json.comment, list: json.body.list, isLoading: false })
    }

    render() {
        return (
            <div>
                <Header name={this.state.name} comment={this.state.comment} list={this.state.list} isLoading={this.state.isLoading} />
                <List list={this.state.list} isLoading={this.state.isLoading} />
                <Footer />
            </div>
        )
    }
}

export default App;