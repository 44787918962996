import 'babel-polyfill'

import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Link, Switch } from 'react-router-dom'

import './index.scss';

import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux';

// import registerServiceWorker from './registerServiceWorker';

import App from './components/App'

// import configureStore from './store';

// const store = configureStore();


// // const store = createStore(rootReducer)

ReactDOM.render((
    <BrowserRouter>
        {/* <Provider store={store}> */}
        <Route path="/" component={App} />
        {/* </Provider> */}
    </BrowserRouter>
), document.getElementById('root'))

// registerServiceWorker();