import React from 'react';

const Footer = () => (
    <footer className='footer'>
        <div className='content has-text-centered'>
            <p>
                Generated by <strong>Bucket List Maker</strong>
            </p>
            <p>
                <a target="_blank" rel="nofollow" href="https://itunes.apple.com/us/app/bucket-list-maker/id1303010424">
                    <img src={require("../app_store@3x.png")} width="120" height="40" />
                </a>
            </p>
        </div>
    </footer >
)

export default Footer
