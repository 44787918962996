import React from 'react';

export class HeaderTitle extends React.Component {
    render() {
        <h1 className='title'>{this.props.title}</h1>
    }
}

export class Header extends React.Component {
    headerTitle() {
        if (this.props.isLoading) {
            return <h1 className='title'>Loading...</h1>
        } else {
            document.title = `${this.props.name}'s bucket list`

            return <h1 className='title'>{this.props.name}'s bucket list</h1>
        }
    }

    countStr() {
        if (this.props.isLoading) {
            return null
        } else {
            const done = this.props.list.filter(item => item.done_at).length
            const total = this.props.list.length
            return (
                <div className='column has-text-light'>
                    {done} / {total} Completed <span className="has-text-success is-size-6"><i className="fa fa-check" /></span>
                </div>

            )
        }
    }

    render() {
        const lines = this.props.comment && this.props.comment.split("\n") || []

        return (
            <section className='hero is-info is-bold'>
                <div className='container'>
                    <div className='hero-body'>
                        <div className='columns'>
                            <div className='column is-three-quarters'>
                                {this.headerTitle()}
                            </div>
                        </div>
                        <div className='columns'>
                            <div className='column'>
                                {lines.map((line, i) => <p key={i}>{line}</p>)}
                            </div>
                        </div>
                        <div className='columns'>
                            {this.countStr()}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Header;;
