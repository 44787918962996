import React from 'react';

export class ListItem extends React.Component {
    hasDone() {
        return !!this.props.item.done_at
    }

    articleClass() {
        if (this.hasDone()) {
            return 'is-success'
        } else {
            return ''
        }
    }

    doneOn() {
        if (this.hasDone()) {
            const date = new Date(this.props.item.done_at)
            return date.toLocaleDateString()
        } else {
            return ''
        }
    }

    doneMark() {
        if (this.hasDone()) {
            return <span className="has-text-success is-size-6">&nbsp;<i className="fa fa-check" /></span>
        } else {
            return ''
        }
    }

    noteBlock() {
        if (/^\s*$/.test(this.props.item.note)) {
            return null
        } else {
            return (
                <div className="has-text-grey" style={{ marginLeft: "1.5rem", paddingBottom: "1rem" }}>
                    {this.props.item.note.split("\n").map((line, i) => <p key={i}>{line}</p>)}
                </div>
            )
        }
    }

    render() {
        return (
            <li style={{ marginBottom: "0.5rem" }}>
                <h3 className='is-4'>
                    #{this.props.number}&nbsp;
                                {this.props.item.title}
                    {this.doneMark()}
                </h3>
                {this.noteBlock()}
            </li>
        )
    }
}

export class List extends React.Component {
    render() {
        return (
            <section className='section'>
                <div className='container'>
                    <ul>
                        {this.props.list.map((item, i) => <ListItem key={i} number={i + 1} item={item} />)}
                    </ul>
                </div>
            </section>
        )
    }
}

export default List;
